@media screen and (max-width: 899px){

  
    nav > *{
      font-size: 15px;
      color: black;
    }
  



  }

  @media screen and (max-width: 390px){

    .navbar-brand> div >svg{
      width: 100%;
    }
    .navbar-brand{
      width: 60%
    }
  }

  @media screen and (max-width: 310px){
    .navbar-brand{
      width: 60% !important;
      margin: 1px;
    }
  }


  .nav-link{
      color: black!important;
      text-decoration: underline;
      margin: 16px;
  }

  .nav-link.active{
      color: white !important;
      background-color: #231E20;
      border-radius: 3px;
      text-decoration: none;
  }

nav{
    background-color: #FFFFFF;
    font-size: 18px;
    font-weight:500;
}


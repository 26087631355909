@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url("./Header.css");
@import url("./Form.css");
@import url("./Comparison.css");
@import url("./Footer.css");
@import url("./Updates.css");
@import url("./Download.css");

body{
  padding-bottom: 0;
}

.container.main-container{
  background-color: white;
  min-height: 100vh;
  padding: 40px;  
}

@media screen and (min-width: 900px){
  #right-about-box{
    border-right: 5px solid black;
  }

}

@media screen and (max-width: 899px){
  .main-container{
    width: 100vw;
    padding: 0;
  }


  #aboutMeImg{
    display: none;
  }


}

@media screen and (max-width: 350px){
  .container.main-container{
    width: 100vw;
    padding: 20px;
  }

}


.fade-in{
  animation: fadeIn linear 1s;

}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}


.inline-drop{
  display: inline-block
}


#aboutMeImg{
  height: auto;
  max-height: 80vh
}



.center{
  text-align: center;
}



*{
  font-family: Roboto;
}

hr{
  color: #D1D2D4;
  height: 2px !important;
}

